<template>
  <base-index-layout :title="$tc('sale.name', 2)">
    <template #new-button>
      <base-new-button class="mx-4" :to="{ path: 'new' }" append />
    </template>
    <SaleOrderIndexTable />
  </base-index-layout>
</template>

<script>
import SaleOrderIndexTable from './components/SaleOrderIndexTable'
export default {
  components: {
    SaleOrderIndexTable
  }
}
</script>
