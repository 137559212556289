var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{attrs:{"outlined":""}},[_c('v-card-title',[_c('v-spacer'),_c('base-field-input',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-spacer')],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.orders,"loading":_vm.isLoading,"loader-height":"1","items-per-page":50,"sort-by":"attributes.createdAt","search":_vm.search,"footer-props":{
      itemsPerPageOptions: [25, 50, 75, 100, -1]
    }},scopedSlots:_vm._u([{key:`item.attributes.status`,fn:function({ item }){return [_c('base-status-chip',{attrs:{"x-small":"","status":item.attributes.status}})]}},{key:`item.attributes.customAttributes.name`,fn:function({ item }){return [_c('p',{staticClass:"info--text subtitle-2 font-weight-medium mb-1"},[_vm._v(" "+_vm._s(item.attributes.customAttributes.name)+" ")]),(item.attributes.createdAt)?_c('span',{staticClass:"text--secondary body-2"},[_vm._v(_vm._s(_vm._f("moment")(item.attributes.createdAt,'MMM Do YYYY')))]):_vm._e()]}},{key:`item.attributes.estimatedUnloadDateTo`,fn:function({
        item
      }){return [(item.attributes.estimatedUnloadDateTo)?_c('span',{staticClass:"body-2"},[_vm._v(_vm._s(_vm._f("moment")(item.attributes.estimatedUnloadDateTo,'MMM Do YYYY')))]):_vm._e()]}},{key:`item.id`,fn:function({ item }){return [_c('BaseTableActions',{attrs:{"module-name":"saleOrder","item":item,"to-show":{
          name: 'sale-order-show',
          params: { orderId: item.id }
        },"to-edit":{
          name: 'sale-order-edit',
          params: {
            orderId: item.id
          }
        }}})]}}],null,true)})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }