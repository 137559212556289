<template>
  <v-card outlined>
    <v-card-title>
      <v-spacer></v-spacer>
      <base-field-input
        v-model="search"
        append-icon="mdi-magnify"
        label="Search"
        single-line
        hide-details
      ></base-field-input>
      <v-spacer></v-spacer>
    </v-card-title>
    <v-data-table
      :headers="headers"
      :items="orders"
      :loading="isLoading"
      loader-height="1"
      :items-per-page="50"
      sort-by="attributes.createdAt"
      :search="search"
      :footer-props="{
        itemsPerPageOptions: [25, 50, 75, 100, -1]
      }"
    >
      <!-- 
      Status 
    -->
      <template v-slot:[`item.attributes.status`]="{ item }">
        <base-status-chip x-small :status="item.attributes.status" />
      </template>

      <!-- 
      Shipment name 
    -->
      <template v-slot:[`item.attributes.customAttributes.name`]="{ item }">
        <p class="info--text subtitle-2 font-weight-medium mb-1 ">
          {{ item.attributes.customAttributes.name }}
        </p>
        <span v-if="item.attributes.createdAt" class="text--secondary body-2">{{
          item.attributes.createdAt | moment('MMM Do YYYY')
        }}</span>
      </template>

      <!-- 
      ETD
    -->
      <template
        v-slot:[`item.attributes.estimatedUnloadDateTo`]="{
          item
        }"
      >
        <span v-if="item.attributes.estimatedUnloadDateTo" class="body-2">{{
          item.attributes.estimatedUnloadDateTo | moment('MMM Do YYYY')
        }}</span>
      </template>

      <template v-slot:[`item.id`]="{ item }">
        <BaseTableActions
          module-name="saleOrder"
          :item="item"
          :to-show="{
            name: 'sale-order-show',
            params: { orderId: item.id }
          }"
          :to-edit="{
            name: 'sale-order-edit',
            params: {
              orderId: item.id
            }
          }"
        />
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex'
import { ORGANIZATION_ID } from '@/common/config.js'
import BaseTableActions from '@/components/buttons/BaseTableActions'
export default {
  name: 'SaleOrderIndexTable',
  components: {
    BaseTableActions
  },
  data() {
    return {
      search: '',
      isLoading: true
    }
  },
  computed: {
    ...mapGetters({
      isAdmin: 'auth/isAdmin',
      orders: 'saleOrder/orders'
    }),
    headers() {
      return this.createTableHeaders()
    }
  },
  async created() {
    this.isLoading = true
    await this.$store.dispatch('saleOrder/getMappedOrders', {
      organizationId: ORGANIZATION_ID(),
      subCategory: 'sale',
      partyCategory: 'Client',
      loadCategory: 'Origin'
    })
    this.isLoading = false
  },
  methods: {
    createTableHeaders() {
      return [
        {
          text: this.$t('sale.shortName') + '#',
          align: 'start',
          sortable: false,
          value: 'attributes.customAttributes.name'
        },
        {
          text: this.$tc('user.customer', 1),
          value: 'attributes.clientCompanyName'
        },
        {
          text: this.$t('load.etd'),
          value: 'attributes.estimatedUnloadDateTo'
        },
        {
          text: this.$t('common.status'),
          value: 'attributes.status'
        },
        {
          text: '',
          value: 'id',
          sortable: false,
          align: 'end'
        }
      ]
    }
  }
}
</script>
